import { useContext, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { UserContext } from 'components/App';
import { requestAPI } from 'API/requestApi';

const sendMessage = async (data: { channelId: string; message: string; file: File | null; fileTitle: string | null; messageTs?: string }): Promise<{ channelId: string; messageTs: string }> => {
	let filePayload = null;

	if (data.file) {
		filePayload = {
			data: await toBase64(data.file),
			filename: data.file.name,
			title: data.fileTitle,
		};
	}

	return requestAPI('/admin/santa/message', 'POST', { 'Content-type': 'application/json' }, JSON.stringify({ ...data, ...(filePayload ? { file: filePayload } : {}) }));
};

const editMessage = (data: { channelId: string; messageTs: string; message: string }): Promise<{ channelId: string; messageTs: string }> => {
	return requestAPI('/admin/santa/message', 'PUT', { 'Content-type': 'application/json' }, JSON.stringify({ ...data }));
};

const deleteMessage = (data: { channelId: string; messageTs: string }): Promise<{ channelId: string; messageTs: string }> => {
	return requestAPI('/admin/santa/message', 'DELETE', { 'Content-type': 'application/json' }, JSON.stringify({ ...data }));
};

const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
	const reader = new FileReader();

	reader.readAsDataURL(file);
	reader.onload = () => {
		const result = reader.result as string | null;

		if (!result) {
			return reject(new Error('Reader result is empty'));
		}
		const rawData = result.replace(/^[^,]+,/, '');

		resolve(rawData);
	};
	reader.onerror = reject;
});

const SantaAdmin = () => {
	const inputRef = useRef<HTMLInputElement>(null);
	const user = useContext(UserContext);
	const [text, setText] = useState('');
	const [file, setFile] = useState<File | null>(null);
	const [fileTitle, setFileTitle] = useState<string | null>(null);
	const [channelId, setChannelId] = useState('');
	const [lastMessageTs, setLastMessageTs] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);

	// hide UI from everyone else. Validation is still done on the backend.
	const isGraybeard = ['matej.skrbis@inova.si', 'dominik.korosec@inova.si'].includes(user?.email ?? '');

	if (!isGraybeard) {
		return <></>;
	}

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setText(e.target.value);
	};

	const isNotReadyToSend = (Boolean(file) !== Boolean(fileTitle)) || (!Boolean(file) && !Boolean(text)) || !channelId;

	return <div style={{}}>
		<p>Napiši sporočilo kot 🎅🏻:</p>
		<p>V kanal (ID): <input type='text' value={channelId} onChange={(evt) => setChannelId(evt.target.value)}></input></p>
		<p>sklic / message_ts (opcijsko): <input type='text' value={lastMessageTs} onChange={(evt) => setLastMessageTs(evt.target.value)}></input> </p>
		<p><input type='button' onClick={() => setChannelId('C065RTX0PU0')} value='(DEV) AOC'></input><input type='button' onClick={() => setChannelId('C05R38QL88H')} value='(PROD) AOC'></input><input type='button' onClick={() => setChannelId('C03HA2RH3')} value='General'></input></p>
		<textarea
			cols={40}
			rows={5}
			value={text}
			spellCheck={'false'}
			onChange={handleChange} />
		<br />
		<input ref={inputRef} onChange={(evt) => {
			const selectedFile = evt.target.files?.[0] ?? null;

			setFile(selectedFile);
			if (!selectedFile) {
				setFileTitle(null);
			}
		}} type="file" accept="*/*" multiple={false} />
		{file && <button onClick={() => {
			setFile(null);
			setFileTitle(null);
			inputRef.current!.value = '';
		}}>[X]</button>}
		{file && <><br /><label>Naslov datoteke: </label><input type="text" value={fileTitle ?? ''} onChange={(evt) => setFileTitle(evt.target.value)}></input></>}
		<p>
			<Button disabled={loading || isNotReadyToSend} onClick={async () => {
				setLoading(true);
				const response = await sendMessage({ channelId, message: text, file, fileTitle, ...(lastMessageTs ? { messageTs: lastMessageTs } : {}) });

				setLastMessageTs(response.messageTs);
				setLoading(false);
			}}>
				{lastMessageTs ? 'Thread' : 'Pošlji'}
			</Button>
			{lastMessageTs ? <Button disabled={loading} color='warning' onClick={async () => {
				setLoading(true);
				const response = await editMessage({ channelId, messageTs: lastMessageTs, message: text });

				setLastMessageTs(response.messageTs);
				setLoading(false);
			}}>Posodobi</Button> : <></>}
			{lastMessageTs ? <Button disabled={loading} color='error' onClick={async () => {
				setLoading(true);
				await deleteMessage({ channelId, messageTs: lastMessageTs });
				setLastMessageTs('');
				setLoading(false);
			}}>Izbriši</Button> : <></>}
		</p>
	</div >;
};

export default SantaAdmin;
