import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { UserContext } from '../App';
import { signOut } from 'firebase/auth';
import { auth, getMessagingRegistrationToken } from '../../config/initFirebase';
import Button from '@mui/material/Button';
import mapFirebaseAuthErrorCodesToDescription from 'utils/mapFirebaseAuthErrorCodesToDescription';
import { Tooltip } from '@mui/material';
import { useAppDispatch } from 'state/hooks';
import { showHideInfoDialog, showHideNotificationHintState } from 'state/tasks/tasksSlice';
import { ParamTypes } from 'types/ParamTypes';
import AOCHeaderLink from 'components/AOCHeaderLink';
import { getBackgroundZenMode, setBackgroundZenMode } from 'components/SnowBackground';
import { getUserProfile, userSubscribeNotifications, userUnsubscribeNotifications } from 'API/users';
import { useIsInternalUser } from 'hooks/useIsInternalUser';
import { getCurrentSeasonInfo } from '../Background/season';

const prefix = 'Header';
const classes = {
	headerToolbarContainer: `${prefix}-headerToolbarContainer`,
	loginLogoutContainer: `${prefix}-loginLogoutContainer`,
};
const StyledDiv = styled('div')(({ theme }) => ({
	[`&.${classes.headerToolbarContainer}`]: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '4px 8px 4px 8px',
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
		},
	},
	[`&.${classes.loginLogoutContainer}`]: {
		textAlign: 'right',
		flex: 1,
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
		},
	},
}));

interface IButtonAppBar {
	signIn: () => void;
}

const AppBar: React.FC<IButtonAppBar> = (props) => {
	const { signIn } = props;
	const { eventId } = useParams() as ParamTypes;
	const user = useContext(UserContext);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [zenMode, setZenMode] = useState(getBackgroundZenMode());
	const [notifications, setNotifications] = useState<boolean | null>(null);
	const [denyNotifications, setDenyNotifications] = useState<boolean>(false);

	const getPermissions = (): 'default' | 'denied' | 'granted' => {
		if (!('Notification' in window)) {
			return 'denied';
		}

		return Notification.permission;
	};

	const getNotificationsGlobalState = (): boolean | null | undefined => {
		const windowGlobals = window as any;

		return windowGlobals.AOCNotificationsState;
	};

	const setNotificationsGlobalState = (notify: boolean) => {
		const windowGlobals = window as any;

		windowGlobals.AOCNotificationsState = notify;
	};

	const sleepMs = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

	const updateNotifications = useCallback(async (notify: boolean) => {
		async function processNotificationsAsync() {
			if (!user) {
				return;
			}
			setNotifications(null);
			setDenyNotifications(false);
			if (notify) {
				let token: string | null = null;

				// retry as subscription of service worker could still be in installing state.
				for (let i = 0; i <= 5; i++) {
					await sleepMs(i * 1000);
					try {
						token = await getMessagingRegistrationToken().catch((err) => {
							if (err.code === 'messaging/permission-blocked') {
								return null;
							}
							throw err;
						});
						break;
					} catch (err: any) {
						// eslint-disable-next-line no-console
						console.warn('Messaging registration token error:', err.code, err);
					}
				}

				if (token === null) {
					setDenyNotifications(true);

					return null;
				}
				await userSubscribeNotifications({ token });
			} else if (getPermissions() === 'granted') {
				// only ask for token if permissions were granted. Otherwise there is no token to be sent
				// (we don't want to annoy the user with token request)
				const token = await getMessagingRegistrationToken();

				await userUnsubscribeNotifications({ token });
			} else {
				await userUnsubscribeNotifications({ token: null });
			}
			setNotifications(notify);
			setNotificationsGlobalState(notify);
		}

		return await processNotificationsAsync().catch((err) => {
			setNotifications(null);
			setDenyNotifications(true);
			// eslint-disable-next-line no-console
			console.error('problem processing notifications:', err);
		});
	}, [setDenyNotifications, user]);

	const logOut = async () => {
		try {
			await signOut(auth);
			navigate('/');
		} catch (e) {
			if (e instanceof Error) {
				dispatch(showHideInfoDialog({ title: 'Napaka', message: mapFirebaseAuthErrorCodesToDescription(e.message) }));
			}
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	useEffect(() => {
		setBackgroundZenMode(getBackgroundZenMode());

		return () => setBackgroundZenMode(getBackgroundZenMode());
	}, []);

	useEffect(() => {
		async function userProfileGet() {
			const data = await getUserProfile();
			const hasNotifications = data.profile.notifications ?? false;

			updateNotifications(hasNotifications);
		}
		if (!user) {
			return;
		}

		const notifyState = getNotificationsGlobalState();

		if (notifyState === undefined) {
			userProfileGet();
		} else {
			setNotifications(notifyState);
		}
	}, [updateNotifications, user]);
	const notificationHintStateSet = useCallback((setHintState: boolean | undefined) => dispatch(showHideNotificationHintState(setHintState)), [dispatch]);

	const notificationsExplicitlyBlocked = getPermissions() === 'denied' || denyNotifications;

	useEffect(() => {
		notificationHintStateSet(notifications == undefined ? undefined : !notifications);
	});

	const isInternalUser = useIsInternalUser();
	const seasonInfo = getCurrentSeasonInfo();

	return <StyledDiv className={classes.headerToolbarContainer}>
		{user && <div>
			<AOCHeaderLink path={'/events'} name={'DOMOV'}></AOCHeaderLink>
			{eventId && <>
				<AOCHeaderLink path={`/events/${eventId}/tasks-preview/`} name={'NALOGE'}></AOCHeaderLink>
				<AOCHeaderLink path={`/events/${eventId}/leaderboard/`} name={'LESTVICA'}></AOCHeaderLink>
				<AOCHeaderLink path={`/events/${eventId}/rewards`} name={'NAGRADE'}></AOCHeaderLink>
			</>}
			<AOCHeaderLink path={eventId ? `/events/${eventId}/rules` : '/rules'} eventId={eventId} name={'PRAVILA'}></AOCHeaderLink>
			<AOCHeaderLink path={eventId ? `/events/${eventId}/credits` : '/credits'} eventId={eventId} name={'EKIPA'}></AOCHeaderLink>
			{isInternalUser && <AOCHeaderLink external={true} path='https://inovait.slack.com/archives/C05R38QL88H' name={'#SLACK☕'}></AOCHeaderLink>}
			{!isInternalUser && <AOCHeaderLink path={eventId ? `/events/${eventId}/contact` : '/contact'} eventId={eventId} name={'KONTAKT'}></AOCHeaderLink>}
		</div>}
		<StyledDiv className={classes.loginLogoutContainer}>
			{!user && <Button onClick={signIn}>[{seasonInfo.loginText}]</Button>}
			{user && <Tooltip title={seasonInfo.editProfileText}><span><AOCHeaderLink path={'/profile'} eventId={eventId} name={user.displayName || user.email!}></AOCHeaderLink></span></Tooltip>}
			{user && <Tooltip title={`${(!notifications ? (notificationsExplicitlyBlocked ? 'Obvestila so onemogočena' : notifications === null ? 'Nalagam obvestila...' : 'Obvesti me ob novih nalogah!') : 'Ne obvesti me ob novih nalogah.')}`}><span>
				<Button onClick={() => updateNotifications(!notifications)} disabled={(notificationsExplicitlyBlocked || notifications === null)} style={{ minWidth: 10 }}>
					[<span style={{ width: '20px' }}>{!notifications ? '🔔' : '🤫'}</span>]
				</Button></span>
			</Tooltip>}
			{user && <Tooltip title={`${(!zenMode ? seasonInfo.animationModeText : seasonInfo.zenModeText)}`}><Button style={{ minWidth: 10 }} onClick={() => {
				setZenMode(!zenMode);
				setBackgroundZenMode(!zenMode);
			}}>[<span style={{ width: '20px' }}>{!zenMode ? seasonInfo.animationModeIcon : seasonInfo.zenModeIcon}</span>]</Button></Tooltip>}
			{user && <Tooltip title={seasonInfo.logoutText}><Button style={{ minWidth: 10 }} onClick={logOut}>[{seasonInfo.logoutIcon}]</Button></Tooltip>}
		</StyledDiv>
	</StyledDiv >;
};

export default AppBar;
