export const enum Season {
	Christmas = 0,
	Halloween = 1,
}

export interface SeasonInfo {
	zenModeText: string;
	zenModeIcon: string;
	animationModeText: string;
	animationModeIcon: string;
	letterColorSequence: string[];
	editProfileText: string;
	loginInfoAdditionalText: JSX.IntrinsicElements['span'];
	loginText: string;
	logoutText: string;
	logoutIcon: string;
	mainTitle: string;
}

export const getCurrentSeason = (): Season => {
	const currentDate = new Date();
	const currentMonth = currentDate.getMonth();
	const currentDay = currentDate.getDate();

	// Return Halloween two weeks before 31. in October and first week of November.
	if ((currentMonth === 9 && currentDay >= 17) ||
		(currentMonth === 10 && currentDay <= 7)
	) {
		return Season.Halloween;
	}

	return Season.Christmas;
};

export const getCurrentSeasonInfo = (): SeasonInfo => {
	const defaultInfo: SeasonInfo = {
		zenModeText: 'Počutim se božično!',
		zenModeIcon: '🎄',
		animationModeText: 'Želim se zbrati',
		animationModeIcon: '🧹',
		letterColorSequence: ['#970604', '#ebaa6a', '#ED3434', '#ce8852', '#00B341', '#DDC898'],
		editProfileText: 'Si želiš urediti svoj profil?',
		loginText: 'Prijava',
		loginInfoAdditionalText: (<span>
			&nbsp;in vstopi v čarobni svet prazničnih dogodivščin ter postani nepogrešljiv del ekipe
			najboljših Božičkovih pomočnikov!<br /><br />&gt; Na polarnem kontinentu te
			čakajo:
			<div>
				<ul style={{ listStyleType: 'none' }}>
					<li>
						* <em>Božične programerske naloge</em>, obarvane z zgodbami in
						prigodami s polarnega kontinenta. Rešuj uganke, razvozljaj skrivnosti
						in pomagaj Božičku pravočasno pripraviti vse za najlepši večer v letu.
					</li>
					<br />
					<li>
						* <em>Tekmovanja z drugimi pomočniki</em>, kjer boš lahko
						dokazal svojo hitrost, iznajdljivost in znanje ter se morda povzpel
						med najboljše in postal Božičkov najljubši pomočnik.
					</li>
					<br />
					<li>
						* <em>Različne kriterjiske lestvice</em>, ki prinašajo dodatno mero izziva.
						Pomeri se iz različnih vidikov in dokaži, da si resnično nepremagljiv.
					</li>
					<br />
					<li>
						* <em>Posebne nagrade za izvrstne dosežke</em>. Če se znajdeš
						med najboljšimi, te bo pod smrečico čakala nagrada, ki bo tvoje dosežke naredila še bolj
						praznične.
					</li>
				</ul>
			</div>
		</span>),
		logoutText: 'Zapusti polarni kontinent',
		logoutIcon: '🚪',
		mainTitle: 'Advent programiranja',
	};

	switch (getCurrentSeason()) {
		case Season.Halloween:
			return {
				...defaultInfo,
				zenModeText: 'Ničesar se ne bojim!',
				zenModeIcon: '👻',
				letterColorSequence: ['#FF6F00', '#D35400', '#FFA500', '#FF8C42', '#CC5500'],
				loginInfoAdditionalText: (<span>
					&nbsp;in vstopi v čarobni svet prazničnih dogodivščin ter postani nepogrešljiv del ekipe
					najboljših Božičkovih pomočnikov!<br /><br />&gt; Na polarnem kontinentu, kjer ledeni jesenski veter šepeta shrljive zgodbe o prekletih bitjih, te
					čakajo:
					<div>
						<ul style={{ listStyleType: 'none' }}>
							<li>
								* <em>Tematske programerske naloge</em>, obarvane z zgodbami in
								prigodami s polarnega kontinenta. Rešuj uganke, razvozljaj skrivnosti
								in odkrij, kaj se skriva za danimi izzivi.
							</li>
							<br />
							<li>
								* <em>Tekmovanja z drugimi pomočniki</em>, kjer boš lahko
								dokazal svojo hitrost, iznajdljivost in znanje ter se morda povzpel
								med najboljše in postal Božičkov najljubši pomočnik.
							</li>
							<br />
							<li>
								* <em>Različne kriterjiske lestvice</em>, ki prinašajo dodatno mero izziva.
								Pomeri se iz različnih vidikov in dokaži, da si resnično nepremagljiv.
							</li>
							<br />
							<li>
								* <em>Posebne nagrade za izvrstne dosežke</em>. Če se znajdeš
								med najboljšimi, lahko pričakuješ nagrado, ki bo tvoje dosežke naredila še bolj
								praznične.
							</li>
						</ul>
					</div>
				</span>),
			};
		case Season.Christmas:
		default:
			return defaultInfo;
	}
};
