import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledTypography = styled(Typography)(() => ({
	fontSize: '18px!important',
	color: 'red',
}));

const Credits = () => {
	return (<div style={{ margin: '16px 0 0 16px', fontFamily: '\'Press Start 2P\'' }}>
		<StyledTypography variant='h4'>Božični dekoratorji:</StyledTypography>
		<ul style={{ fontSize: '1.0rem', fontFamily: '\'Press Start 2P\'', listStyleType: 'none' }}>
			<li>* Viktorija Talevska Kuzmanova</li><br />
			<li>* Žiga Lah</li><br />
			<li>* Dominik Korošec</li><br />
			<li>* Matej Skrbiš</li><br />
			<li>* Julija Pestiček</li><br />
		</ul>

		<StyledTypography variant='h4'>Pomagači v delavnici:</StyledTypography>
		<ul style={{ fontSize: '1.0rem', fontFamily: '\'Press Start 2P\'', listStyleType: 'none' }}>
			<li>* Dominik Korošec</li><br />
			<li>* Matej Skrbiš</li><br />
			<li>* Jakob Polegek</li><br />
		</ul>
		<StyledTypography variant='h4'>Arhitekti zgodb:</StyledTypography>
		<ul style={{ fontSize: '1.0rem', fontFamily: '\'Press Start 2P\'', listStyleType: 'none' }}>
			<li>* Dominik Korošec</li><br/>
			<li>* Matej Skrbiš</li><br/>
		</ul>
	</div>);
};

export default Credits;
