import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledTypography = styled(Typography)(() => ({
	fontSize: '18px!important',
	color: 'red',
}));

const Contact = () => {
	return (<div style={{ margin: '16px 0 0 16px', fontFamily: '\'Press Start 2P\'' }}>
		<StyledTypography variant='h4'>Nas želiš kontaktirati?</StyledTypography>
		<ul style={{ fontSize: '1.0rem', fontFamily: '\'Press Start 2P\'', listStyleType: 'none' }}>
			<li>* Splošna vprašanja:

				<ul style={{ listStyleType: 'none' }}>
					<br />
					<li>@ <a href="mailto:info@inova.si">info@inova.si</a></li>
				</ul>
			</li>
			<br />
			<li>* Težave s portalom ali nalogami: <ul style={{ listStyleType: 'none' }}>
				<br />
				<li>
					@ <a href="mailto:matej.skrbis@inova.si">matej.skrbis@inova.si</a>
				</li>
				<br />
				<li>
					@ <a href="mailto:dominik.korosec@inova.si">dominik.korosec@inova.si</a>
				</li>
			</ul></li>
			<br />
			<li>* Več informacij o nas najdeš na:
				<ul style={{ listStyleType: 'none' }}>
					<br/>
					<li>&gt; <a target="_blank" rel="noopener noreferrer" href="https://inova.si">https://inova.si</a></li>
				</ul>
			</li>
			<br />
		</ul>
	</div>);
};

export default Contact;
